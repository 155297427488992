import axios from 'axios';

const instance = axios.create({
  baseURL: "https://www.happygifter.com",
  withCredentials: true,
});

export default instance;

//local url 
// http://localhost:5001
//heroku url
//https://www.happygifter.com
